
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { MarcacaoAgua } from '@/core/models/agua';
import { HidrometroService, TabelaValorConsumoAguaService } from '@/core/services/agua';
import { ReceitaService } from '@/core/services/financeiro';
import { ConstruirQueryParams, FormatarNumeroTelefone, TratarErroApi } from '@/assets/scripts/helper';
import { EnumTipoOperacaoFinanceira } from '@/core/models/cadastros';
 
@Component 
export default class ListaLancamento extends mixins(Vue,ListPage) {
  service =  new HidrometroService();
  receitaService = new ReceitaService();

  item = new MarcacaoAgua();

  serviceTabela = new TabelaValorConsumoAguaService();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  menu = false

  titulo: string = 'Marcação de Água';
  subTitulo: string = 'Lançamento cadastrados no Sistema';

  filter = {dataReferencia:new Date().yyyymmdd()};
  listaTabela = [];

  options: any = {
    itemsPerPage: 15
  };  
 
  headers: any[] = [ 
    { text: '', value: 'actions', sortable: false },
    { text: 'Lote', value: 'lote', sortable: false },
    { text: 'Proprietário', value: 'proprietario', sortable: false },
    { text: 'Hidrômetro', value: 'hidrometro', sortable: false },
    { text: 'Data de Aferição', value: 'dataAfericao', sortable: false },
    { text: 'Leitura Anterior', value: 'leituraAnterior', sortable: false },
    { text: 'Leitura Final', value: 'leituraFinal', sortable: false },
    { text: 'Responsável', value: 'responsavel', sortable: false },
    { text: 'Boleto', value: 'boleto', sortable: false },
    { text: 'Média', value: 'media', sortable: false },
    { text: 'Consumo', value: 'consumo', sortable: false },
    { text: 'Valor', value: 'valor', sortable: false },
  ]; 

  @Watch("options", { deep: true })
  Atualizar() { 
    this.loading = true;
    this.service.ObterMarcacoes(this.filter).then(
      (res) => {
        res.data.forEach(element => {
          element.dataAfericao = new Date(element.dataAfericao).yyyymmdd();
        });
        this.lista = res.data 
        this.totalLista = res.data.count;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  SalvarMarcacao(item){
    if (!this.ValidarData(item)) {
      return; 
    }

    this.CalcularValorTabela(item); 
    
    item.responsavelId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    (item.id > 0 ? this.service.AlterarMarcacao(item.id, item) : this.service.SalvarMarcacao(item.hidrometroId, item)).then(
      (res) => {
        this.$emit("salvou"); 
        this.Atualizar();
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })  
  } 

  CalcularValorTabela(item){
    item.consumo = Number(item.leituraFinal - item.leituraAnterior);
    item.valor = 0;

    this.listaTabela.forEach((element:any) => {
 
      if(element.isPrincipal){
        let consumoAux = item.consumo; 
        let cont = 0;

        for (const tabela of element.itens) {
          cont++;

          if(consumoAux <= 0)
            break;

          if(item.consumo >= tabela.faixaConsumoInicial){
            if(tabela.isValorFixo == true)
              {
                item.valor += tabela.valor;
                consumoAux -= tabela.faixaConsumoFinal;
              } 
              else 
              {
                var repeticoes;

                if(cont < element.itens.length)
                  {
                    repeticoes = consumoAux > (tabela.faixaConsumoFinal - (tabela.faixaConsumoInicial -1)) ?  (tabela.faixaConsumoFinal - (tabela.faixaConsumoInicial-1))  :  consumoAux ;
                  }
                else
                  {
                    repeticoes = consumoAux;
                  }

              item.valor += tabela.valor * repeticoes;
              consumoAux -= repeticoes;
            }
          }
        }
      }
    });
  }

  GerarBoleto(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja Gerar o Boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return  this.service.GerarBoleto(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar(); 
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  VisualizarBoleto(item){ 
    new ReceitaService().ObterParcelaPorId(item.id, 'Transacoes').then(
      (res) => {
        let ultimaTransacao = res.data.ultimaTransacao;

        if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Boleto) {
          this.abrirUrlNoNovaJanela("financeiroBoleto", { transacaoId: ultimaTransacao.id });
        } 
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Gateway) {
          if (item.tipoGatewayId === 3) 
            this.abrirUrlNoNovaJanela("RelBoletoSafra", { codigoZoop: item.codigoZoop });
          else 
          window.open(item.urlBoleto, "_blank");
        }
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Banco) {
          this.DownloadDocumento(item.id);
        }
      });
  } 

  EnviarWhatsapp(item){
    var telefone = FormatarNumeroTelefone(item.proprietarioTelefone);
    window.open(`https://api.whatsapp.com/send?phone=55${telefone}&text=Olá, Segue a baixo o link do seu boleto: ${item.parcela.urlBoleto} Ou se preferir, para maior comodidade segue o código do boleto:  ${item.linhaDigitavelBoleto}`)  
  }

  CancelarBoleto(item){ 
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja cancelar o Boleto?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.CancelarBoleto(item.parcela.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar(); 
              return res;
            }
          },
          (err) => {
            TratarErroApi(err)
          }
        )},
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal("Aviso",result.value.data,result.value.status == 200 ? "success" : "warning");
      }
    }); 
  }
  abrirUrlNoNovaJanela(nomeRota, queryParams) {
    let routeLink = this.$router.resolve({name: nomeRota,query: ConstruirQueryParams(queryParams)});
    window.open(routeLink.href, "_blank");
  }

  DownloadDocumento(id: number){
    this.loading = true;

    new ReceitaService().DownloadDocumento(id).then(
      res => {
        let file = new Blob([res.data], {type: res.headers["content-type"]});
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
    .finally(() => this.loading = false);
  }

  ValidarData(item) {
    let dataAfericao = new Date(`${item.dataAfericao} 00:00:00`);
    let dataReferencia = new Date(`${this.filter.dataReferencia} 00:00:00`);

    let monthAfericao = dataAfericao.getMonth() + 1; let anoAfericao = dataAfericao.getFullYear();
    let monthReferencia = dataReferencia.getMonth() + 1; let anoReferencia = dataReferencia.getFullYear();

    if((monthAfericao != monthReferencia) || (anoAfericao != anoReferencia)){
      this.$swal("Aviso",  "Não é possível gerar marcação com a data diferente do mês de referência.", "warning");
      return false;
    }
    return true;
  }

  mounted(){
    this.serviceTabela.Listar(-1, -1, [],[],'',[], '', '' , '', 'Itens').then(res => {
      this.listaTabela = res.data.items;
    })
  }
}
